<template>
  <div class="dashboard-widget-settings">

    <div>На данных графиках можно отображать сразу несколько наборов данных</div>

    <el-tabs
      style="margin-top: 20px"
      addable
      closable
      @tab-add="addSeries"
      @tab-remove="deleteSeries"
    >

      <el-tab-pane
        v-for="(vqb, vqbIndex) in localValue.seriesVqbs"
        :key="vqbIndex"
        :label="localValue.seriesVqbs[vqbIndex].name"
        :name="vqbIndex.toString()"
      >

        <el-form
          label-position="left"
          label-width="200px"
          style="width: 700px"
          size="mini"
        >

          <el-form-item label="Название набора данных">
            <el-input v-model="localValue.seriesVqbs[vqbIndex].name"></el-input>
          </el-form-item>

        </el-form>

        <VQBForm
          v-model="localValue.seriesVqbs[vqbIndex].vqb"
          :select-fields-limit="2"
        ></VQBForm>
      </el-tab-pane>

    </el-tabs>


  </div>
</template>

<script>
import Builder from "@/components/report/builder/Builder.vue";
import VQBForm from "@/components/report/builder/VQBForm.vue";

export default {
  name: 'VQBCellWidgetSettings',
  components: {VQBForm, Builder},
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    localValue: {
      get() {return this.value},
      set(value) {this.$emit('input', value)}
    }
  },
  data(){
    return {}
  },
  methods: {
    addSeries(){
      this.localValue.seriesVqbs.push({
        name: 'Новый набор данных',
        vqb: {
          'elements': [],
          'fields': [],
          'relations': [],
          'search': [],
        }
      })
    },
    deleteSeries(index){
      this.localValue.seriesVqbs.splice(index, 1);
    },
  }
}
</script>